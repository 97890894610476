import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import plusIcon from "../images/faq/plus.png";
import minusIcon from "../images/faq/minus.png";
import plusnull from "../images/footer/plus-null.png";
import minusnull from "../images/footer/minus-null.png";
import { Link } from "gatsby";
import { useLanguage } from "../languageContext";

const AccordionItem = ({ header, index, ...rest }) => (
  <Item
    {...rest}
    headingTag="h2"
    header={
      <>
        {header}
        {index == 0 ? (
          <img className="faq_plus_icon" src={minusnull} alt="Chevron Down" />
        ) : (
          <img className="faq_plus_icon" src={plusnull} alt="Chevron Down" />
        )}
      </>
    }
  />
);
const FAQ = () => {
  const currentL = useLanguage() ?? "en";
  const [currentLanguage, setCurrentLanguage] = useState("en");
  useEffect(() => {
    setCurrentLanguage(currentL);
  }, [currentL]);
  const handleChange = (state) => {
    console.log(state);
    try {
      let elem = state.key;
      let imgTag = elem.querySelector("img");
      if (state.current.isEnter) {
        imgTag.src = minusnull;
      } else {
        imgTag.src = plusnull;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const faqEn = [
    {
      question: "What engineering solutions does Goken provide? ",
      answer: `Goken is an engineering and technology service provider that solves your engineering challenges and accelerates your product development cycles, ultimately helping to build better products. Our wide range of services includes Engineering Design, CAE and Validation, Should Costing and Value Engineering, Automation, CATIA Training, Japanese Language Services, and Knowledge Management. We cater to various industries, including automotive, aerospace, and manufacturing, providing tailored solutions to meet your specific needs. `,
    },
    {
      question: "How does Goken help reduce product time-to-market? ",
      answer: `Reducing time to market is a function of speed of delivery, ability to scale project on demand and ensuring that the job is done right at the first time. Goken has an unique design philosophy called the Goken Way of Product Development. This approach is a scalable and repeatable process designed to cultivate high-performing teams capable of delivering results from day one. Central to our methodology is the deployment of our Knowledge Capture process, which serves as a vital tool for seamlessly transferring information across Goken's dynamic teams. This unique methodology helps our engineers to deliver products at a faster pace and with high quality. `,
    },
    {
      question: "How does Goken optimize product development costs?",
      answer: `Goken implements a cost optimization strategy through Should Cost Analysis principles. This method determines the optimal cost a product "should" incur, considering the most efficient manufacturing and regional processes available. It identifies key cost drivers and offers crucial "Cost Insight" to help make informed decisions during sourcing and manufacturing. These insights encompass material cost, process cost, overheads, and profit margins for a wide variety of commodities and geographies.  `,
    },
    {
      question: "How does Goken ensure high product quality? ",
      answer: `At Goken, we uphold high product quality standards through our unique design philosophy known as the Goken Way of Product Development.  
      The three pillars of Goken Way are Organizational Development, Methodology Development, and Self-Managed Teams. These pillars focus on knowledge sharing from existing teams, implementing effective modeling techniques, and developing templates for streamlined operations. Our Self-Managed Teams play a crucial role in disseminating information seamlessly across onsite, nearshore, and offshore engineers. The Goken Way of Design, a proven technique within our framework, ensures high product quality even amidst a large number of variations.`,
    },
    {
      question:
        "Can Goken Global provide turnkey full vehicle development solutions?",
      answer: `With over 20 years of experience collaborating with leading OEM automotive companies, Goken has a deep understanding of end-to-end vehicle development. Our engineers offer expertise in understanding system level requirements and translate them to component-level details. This is followed by a thorough 3D product design, 2D generation and reporting, as well as creation of design verification and validation documents. Goken Engineers have worked on multiple projects for OEMs right from concept through SOP ( start of production) activities. `,
    },
    {
      question:
        "What services does Goken offer for Software Defined Vehicle segment ? ",
      answer: `Goken is currently working on two functional areas for SDV – Embedded Software Development and Validation & Verification. Goken has a unique path for development of capabilities in these areas. It offers an Embedded Graduation Apprenticeship Program (E-GAP) tailored for fresh graduates with good aptitude for software development. The graduates get trained and also work at selected client locations for building world class products. Our engineers possess expertise in Embedded C programming, hands-on experience in developing cutting-edge products, and familiarity with manufacturing, quality assurance, and sourcing and procurement functions.  `,
    },
    {
      question: "How does Goken ensures client confidentiality? ",
      answer: `At Goken, safeguarding client confidentiality and data security is our utmost priority. We implement a two step security approach that includes virtual and physical measures. This includes utilizing secure devices and networks for accessing, storing, or transmitting client data, to incorporating strong passwords, encryption, antivirus software, and firewall protection. In addition to securing devices, Goken offices are secure physical locations with restricted access only to individuals working in the tech center. We also implement specific measures such as non-disclosure agreements (NDAs), adherence to stringent security standards or protocols, and the use of designated platforms or tools for communication and collaboration, all aimed at ensuring the utmost confidentiality and protection of client information. `,
    },
    {
      question: "Is Goken a global engineering services company?",
      answer: `Absolutely. We operate globally, with a nearshore location in Dublin, Ohio in the US to support our clients in the Midwest and North East. We have an offshore location in Pune, India that supports clients from the US, Japan and Korea. We also have an office in Japan that does some engineering product development and acts a liaison office for our clients in Japan. We offer onsite, nearshore, offshore, and hybrid services to cater to the specific needs of our clients.  `,
    },
    {
      question:
        "What different types of CAD software's are used in automotive design engineering?",
      answer: `In the automotive industry, a diverse range of specialized software tools are utilized to build engineering products. Among these tools, computer-aided design (CAD) software plays a pivotal role. Some of the widely adopted CAD software in this domain include SolidWorks, CATIA, Creo, and Siemens NX. Goken engineers support the product development using all these softwares.  `,
    },
    {
      question:
        "What skills does an engineer need to make a career in the automotive industry? ",
      answer: `An automotive design engineer should possess technical proficiency, including hands-on knowledge of CAD software and a solid understanding of engineering principles such as mechanics and materials. Additionally, they should demonstrate a creative and innovative approach, coupled with a thorough understanding of regulations and standards within the industry. Effective communication skills and staying abreast of industry trends are also crucial for gaining a competitive edge in the market. `,
    },
  ];
  return (
    <Layout hideMap hideFAQ>
      <SEO
        description={`Find answers to common questions about Goken's services, processes, and more in our comprehensive FAQ section.`}
        canonicalLink={"https://www.goken-global.com/faq/"}
        title="Goken: Frequently Asked Questions"
        pageName={"Frequently Asked Questions"}
      />
      <div className="section section-br goken-gray-text">
        <div className="faq-wrapper ">
          <h1 className="faq-page-title heading regular titlecase-text">
            Frequently asked Questions
          </h1>
          <Accordion
            transition
            transitionTimeout={300}
            allowMultiple
            onStateChange={handleChange}
          >
            {faqEn.map(({ question, answer }, index) => {
              return (
                <AccordionItem
                  initialEntered={index == 0}
                  key={index}
                  index={index}
                  header={question}
                >
                  {answer}
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
        <div className="cta-wrapper" style={{ width: "60%", margin: "auto" }}>
          <div
            className="content-wrapper"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              className="para"
              style={{ color: "rgba(0,0,0,0.6)", marginRight: "auto" }}
            >
              Could not find what you are looking for? Write to us and let us
              help you.
            </p>
            <Link
              to="/contactus/"
              className={`reachout-btn ${currentLanguage}-btn`}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
